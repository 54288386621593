import {
  Box,
  Button,
  Col,
  Container,
  Flex,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Paper,
  Radio,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../connector/ModuleHook";
import { RootState } from "./../connector/ModuleStore";
// import {
//   agrovisioFieldDates,
//   agrovisioFieldImage,
//   agrovisioImageTypes,
//   fetchFieldInfo,
// } from "../../../fielddetail/interactor/FieldDetailInteractor";
// import NotificationPanel from "../NotificationPanel";
import { useWindowSize } from "@uidotdev/usehooks";
import CustomLoadingOverlay from "../../utils/CustomLoadingOverlay";
import { CalendarBlank, Info } from "phosphor-react";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import {
  IconArrowLeft,
  IconSearch,
  IconSortDescending,
} from "@tabler/icons-react";
import {
  AssignFormService,
  DetailFormTaskService,
  FormListService,
} from "../interractor/SurveyInterractor";
import { useSelector } from "react-redux";
import { useDebouncedValue } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import { MyFieldService } from "../../myfields/interactor/MyFieldInteractor";
import { EmployeeService } from "../../users/interactor/EmployeeInteractor";
import { toast } from "react-toastify";
import { AssignFormResponse } from "../data/response/AssignFormResponse";
import { AssignFormRequest } from "../data/request/AssignFormRequest";
import { Employee } from "../../users/data/response/EmployeeResponse";

const PAGE_SIZE = 10;
const MainWrapper = lazy(
  () => import("../../../app/common/presentation/view/component/mainWrapper")
);

export const employeeAuthorityType = [
  { text: "Admin", value: 1 },
  { text: "Mühendis", value: 2 },
  { text: "Üretici", value: 3 },
];

const formFieldEmployeeState = [
  { text: "Oluşturuldu", value: 0 },
  { text: "Yarı Tamamlandı", value: 1 },
  { text: "Tamamlandı", value: 2 },
  { text: "İptal Edildi", value: 3 },
];

interface AssignSurveyProps {}

const AssignSurvey: React.FC<AssignSurveyProps> = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const [assignType, setAssignType] = useState<any>(null);
  const [authorityType, setAuthorityType] = useState<any>(null);
  const [form, setForm] = useState<any>(null);
  const [field, setField] = useState<any>(null);
  const [employeeIds, setEmployeeIds] = useState<any>(null);
  const [taskName, setTaskName] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [searchValue, onSearchChange] = useState("");

  const [fieldsPageData, setFieldsPageData] = useState<any[]>([]);
  const [fieldsInitialRecords, setFieldsInitialRecords] = useState<any[]>([]);
  const [sortedFieldsData, setSortedFieldsData] = useState<any[]>([]);
  const [pageFields, setPageFields] = useState(1);
  const [sortStatusFields, setSortStatusFields] = useState<DataTableSortStatus>(
    {
      columnAccessor: "fieldName",
      direction: "desc",
    }
  );
  const [selectedRecordsFields, setSelectedRecordsFields] = useState<any[]>([]);

  const [employeesPageData, setEmployeesPageData] = useState<any[]>([]);
  const [employeesInitialRecords, setEmployeesInitialRecords] = useState<any[]>(
    []
  );
  const [sortedEmployeesData, setSortedEmployeesData] = useState<any[]>([]);
  const [pageEmployees, setPageEmployees] = useState(1);
  const [sortStatusEmployees, setSortStatusEmployees] =
    useState<DataTableSortStatus>({
      columnAccessor: "firstName",
      direction: "asc",
    });
  const [selectedRecordsEmployees, setSelectedRecordsEmployees] = useState<
    any[]
  >([]);

  useEffect(() => {
    setFetching(true);
    if (id) {
      dispatch(DetailFormTaskService({ formTaskId: id }));
    }
  }, [id]);

  const detailFormTaskService = useSelector(
    (state: RootState) => state.DetailFormTaskState.value
  );

  const detailFormTaskData = detailFormTaskService?.data?.formTaskDto;

  const formListService = useSelector(
    (state: RootState) => state.FormListState.value
  );

  const formsData = formListService?.data;

  const employeeService = useSelector(
    (state: RootState) => state.EmployeeState.value
  );

  const employeeData = employeeService?.data?.employees || [];

  const myFieldService = useSelector(
    (state: RootState) => state.MyFieldState.value
  );
  const myFieldData = myFieldService?.data?.fields;

  const [fetching, setFetching] = useState(false);
  const [isDeleteFetching, setIsDeleteFetching] = useState(false);

  useEffect(() => {
    dispatch(MyFieldService());
    dispatch(EmployeeService(true));
  }, []);

  useEffect(() => {
    if (!formsData) {
      setFetching(true);
      dispatch(FormListService());
    }
  }, []);
  useEffect(() => {
    if (formsData?.forms) {
      setFetching(false);
    }
  }, [formsData]);

  useEffect(() => {
    if (myFieldData && employeeData.length > 0) {
      let fields = myFieldData.map((field) => {
        console.log(responsibleEngineers(field.managers, employeeData));

        return {
          id: field.id,
          fieldName: field.fieldName,
          city: field.address?.city?.name,
          district: field.address?.district?.name,
          plant:
            field.seasons.length > 0
              ? field.seasons[field.seasons.length - 1].plantName
              : "",
          parcel: field.address?.parcel,
          block: field.address?.block,
          responsibleEngineers: responsibleEngineers(
            field.managers,
            employeeData
          ),
        };
      });
      setFieldsPageData(fields);
      setFieldsInitialRecords(fields);
    }
  }, [myFieldData, employeeData]);

  const responsibleEngineers = (
    fieldManagers: any[],
    employeeData: Employee[]
  ): string => {
    var managers = fieldManagers.filter((item) =>
      employeeData.some(
        (employee) =>
          employee.id === item.employeeId && employee.authorityType == 2
      )
    );
    return managers.map((item) => item.employeeName).join(", ");
  };
  useEffect(() => {
    if (employeeData) {
      let employees = employeeData
        .filter((item) => item.authorityType === 2)
        .map((employee) => {
          return {
            id: employee.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            phoneNumber: `${employee.phoneNumber.countryCode}${employee.phoneNumber.phoneNumber}`,
          };
        });
      setEmployeesPageData(employees);
      setEmployeesInitialRecords(employees);
    }
  }, [employeeData]);

  const saveButtonClick = () => {
    if (!assignType) {
      toast.error("Atama tipi seçiniz.");
      return;
    }
    if (assignType == "1" && selectedRecordsFields.length == 0) {
      toast.error("En az 1 tarla seçmek zorundasınz.");
      return;
    }
    if (assignType == "2" && selectedRecordsEmployees.length == 0) {
      toast.error("En az bir üretici seçmek zorundasınız.");
      return;
    }

    Swal.fire({
      title:
        "Seçilen" +
        (assignType == "1" ? " tarla" : " üretici") +
        " için görev atamak istediğinize emin misiniz?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Ata!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        assignForm();
      }
    });

    return;
  };

  const assignForm = () => {
    let assignFormRequest: AssignFormRequest = {
      formTaskId: id ?? "",
      fieldId: field,
      employeeIds:
        assignType == "1"
          ? null
          : selectedRecordsEmployees.map((item) => item.id),
      fieldIds:
        assignType == "2" ? null : selectedRecordsFields.map((item) => item.id),
      employeeAuthorityType: assignType == "1" ? 2 : 3,
    };
    setFetching(true);
    dispatch(AssignFormService(assignFormRequest)).then((response) => {
      if (response !== undefined) {
        let responsePayload = response.payload as
          | AssignFormResponse
          | undefined;
        if (responsePayload?.success) {
          MultiSelect.defaultProps = {
            value: [],
          };
          toast.success(responsePayload?.userMessage);
          setFetching(false);
          setTimeout(() => {
            ClearForm();
          }, 2500);
        } else {
          toast.error(responsePayload?.userMessage);
          setFetching(false);
        }
      }
    });
  };

  const resetButtonClick = () => {
    ClearForm();
  };

  const ClearForm = () => {
    setAuthorityType(null);
    setAssignType(null);
    setField(null);
    setEmployeeIds(null);
  };

  const handleSortStatusChangeFields = (
    updatedSortStatus: DataTableSortStatus
  ) => {
    setSortStatusFields(updatedSortStatus);
  };

  const handleSortStatusChangeEmployees = (
    updatedSortStatus: DataTableSortStatus
  ) => {
    setSortStatusEmployees(updatedSortStatus);
  };

  const [fieldNameQuery, setFieldNameQuery] = useState("");
  const [debouncedFieldNameQuery] = useDebouncedValue(fieldNameQuery, 200);

  const [cityQuery, setCityQuery] = useState("");
  const [debouncedCityQuery] = useDebouncedValue(cityQuery, 200);

  const [districtQuery, setDistrictQuery] = useState("");
  const [debouncedDistrictQuery] = useDebouncedValue(districtQuery, 200);

  const [plantQuery, setPlantQuery] = useState("");
  const [debouncedPlantQuery] = useDebouncedValue(plantQuery, 200);

  const [parcelQuery, setParcelQuery] = useState("");
  const [debouncedParcelQuery] = useDebouncedValue(parcelQuery, 200);

  const [blockQuery, setBlockQuery] = useState("");
  const [debouncedBlockQuery] = useDebouncedValue(blockQuery, 200);

  const [responsibleEngineersQuery, setResponsibleEngineersQuery] =
    useState("");
  const [debouncedresponsibleEngineersQuery] = useDebouncedValue(
    responsibleEngineersQuery,
    200
  );

  const fromFields = (pageFields - 1) * PAGE_SIZE;
  const toFields = fromFields + PAGE_SIZE;

  useEffect(() => {
    if (fieldsInitialRecords) {
      setFieldsPageData(fieldsInitialRecords.slice(fromFields, toFields));
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = fieldsInitialRecords.filter(
        ({
          fieldName,
          city,
          district,
          plant,
          parcel,
          block,
          responsibleEngineers,
        }) => {
          if (
            debouncedFieldNameQuery !== "" &&
            fieldNameQuery &&
            !`${fieldName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFieldNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedCityQuery !== "" &&
            cityQuery &&
            !`${city}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedCityQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedDistrictQuery !== "" &&
            districtQuery &&
            !`${district}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedDistrictQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedPlantQuery &&
            plantQuery &&
            !`${plant}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedPlantQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedParcelQuery &&
            parcelQuery &&
            !`${parcel}`
              .toString()
              .includes(debouncedParcelQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedBlockQuery &&
            blockQuery &&
            !`${block}`
              .toString()
              .includes(debouncedBlockQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedresponsibleEngineersQuery &&
            responsibleEngineersQuery &&
            !`${responsibleEngineers}`
              .toString()
              .includes(debouncedresponsibleEngineersQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      );
      let sortedData = [...filteredData];
      if (sortStatusFields.columnAccessor === "fieldName") {
        sortedData.sort((a, b) => {
          const valueA = a.fieldName ? a.fieldName : "";
          const valueB = b.fieldName ? b.fieldName : "";
          return sortStatusFields.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatusFields.columnAccessor === "city") {
        sortedData.sort((a, b) => {
          const valueA = a.city ? a.city.toLowerCase() : "";
          const valueB = b.city ? b.city.toLowerCase() : "";
          return sortStatusFields.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatusFields.columnAccessor === "district") {
        sortedData.sort((a, b) => {
          const valueA = a.district ? a.district.toLowerCase() : "";
          const valueB = b.district ? b.district.toLowerCase() : "";
          return sortStatusFields.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatusFields.columnAccessor === "plant") {
        sortedData.sort((a, b) => {
          const valueA = a.plant ? a.plant.toLowerCase() : "";
          const valueB = b.plant ? b.plant.toLowerCase() : "";
          return sortStatusFields.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatusFields.columnAccessor === "parcel") {
        sortedData.sort((a, b) => {
          return sortStatusFields.direction === "asc"
            ? a.parcel - b.parcel
            : b.parcel - a.parcel;
        });
      } else if (sortStatusFields.columnAccessor === "block") {
        sortedData.sort((a, b) => {
          return sortStatusFields.direction === "asc"
            ? a.block - b.block
            : b.block - a.block;
        });
      } else if (sortStatusFields.columnAccessor === "responsibleEngineers") {
        sortedData.sort((a, b) => {
          const valueA = a.responsibleEngineers
            ? a.responsibleEngineers.toLowerCase()
            : "";
          const valueB = b.responsibleEngineers
            ? b.responsibleEngineers.toLowerCase()
            : "";
          return sortStatusFields.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      }
      setSortedFieldsData(sortedData);
      setFieldsPageData(sortedData.slice(fromFields, toFields));
    };

    applySortingAndFiltering();
  }, [fieldsInitialRecords, pageFields, sortStatusFields]);

  const columnsFields = [
    {
      accessor: "fieldName",
      title: "Tarla Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Tarla Adı"
          description="Belirtilen tarla adı ara."
          placeholder="Tarla adı ara..."
          icon={<IconSearch size={16} />}
          value={fieldNameQuery}
          onChange={(e) => setFieldNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: fieldNameQuery !== "",
    },
    {
      accessor: "city",
      title: "Şehir",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Şehir"
          description="Belirtilen şehirdeki ara."
          placeholder="Şehir adı ara..."
          icon={<IconSearch size={16} />}
          value={cityQuery}
          onChange={(e) => setCityQuery(e.currentTarget.value)}
        />
      ),
      filtering: cityQuery !== "",
    },
    {
      accessor: "district",
      title: "İlçe",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İlçe"
          description="Belirtilen ilçedeki ara."
          placeholder="İlçe adı ara..."
          icon={<IconSearch size={16} />}
          value={districtQuery}
          onChange={(e) => setDistrictQuery(e.currentTarget.value)}
        />
      ),
      filtering: districtQuery !== "",
    },
    {
      accessor: "plant",
      title: "Ürün Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Ürün adı"
          description="Belirtilen ürüm adlarını ara."
          placeholder="Ürün adı ara..."
          icon={<IconSearch size={16} />}
          value={plantQuery}
          onChange={(e) => setPlantQuery(e.currentTarget.value)}
        />
      ),
      filtering: plantQuery !== "",
    },
    {
      accessor: "parcel",
      title: "Parsel",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Parsel"
          description="Belirtilen parseldeki ara."
          placeholder="Parsel no ile ara..."
          icon={<IconSearch size={16} />}
          value={parcelQuery}
          onChange={(e) => setParcelQuery(e.currentTarget.value)}
        />
      ),
      filtering: parcelQuery !== "",
    },
    {
      accessor: "block",
      title: "Ada",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Blok"
          description="Belirtilen blockdaki ara."
          placeholder="Block numarası ara..."
          icon={<IconSearch size={16} />}
          value={blockQuery}
          onChange={(e) => setBlockQuery(e.currentTarget.value)}
        />
      ),
      filtering: debouncedBlockQuery !== "",
    },
    {
      accessor: "responsibleEngineers",
      title: "Sorumlu Mühendis",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Sorumlu Mühendis"
          description="Sorumlu mühendis ara."
          placeholder="Sorumlu mühendis ile ara..."
          icon={<IconSearch size={16} />}
          value={blockQuery}
          onChange={(e) => setResponsibleEngineersQuery(e.currentTarget.value)}
        />
      ),
      filtering: responsibleEngineersQuery !== "",
    },
  ];

  const [firstNameQuery, setFirstNameQuery] = useState("");
  const [debouncedFirstNameQuery] = useDebouncedValue(firstNameQuery, 200);

  const [lastNameQuery, setLastNameQuery] = useState("");
  const [debouncedLastNameQuery] = useDebouncedValue(lastNameQuery, 200);

  const [phoneNumberQuery, setPhoneNumberQuery] = useState("");
  const [debouncedPhoneNumberQuery] = useDebouncedValue(phoneNumberQuery, 200);

  const fromEmployees = (pageEmployees - 1) * PAGE_SIZE;
  const toEmployees = fromEmployees + PAGE_SIZE;

  useEffect(() => {
    if (employeesInitialRecords) {
      setEmployeesPageData(
        employeesInitialRecords.slice(fromEmployees, toEmployees)
      );
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = employeesInitialRecords.filter(
        ({ firstName, lastName, phoneNumber }) => {
          if (
            debouncedFirstNameQuery !== "" &&
            firstNameQuery &&
            !`${firstName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFirstNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedLastNameQuery !== "" &&
            lastNameQuery &&
            !`${lastName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedLastNameQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedPhoneNumberQuery !== "" &&
            phoneNumberQuery &&
            !`${phoneNumber}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedPhoneNumberQuery.trim().toLowerCase())
          ) {
            return false;
          }

          return true;
        }
      );
      console.log(sortStatusEmployees);
      let sortedData = [...filteredData];
      if (sortStatusEmployees.columnAccessor === "firstName") {
        sortedData.sort((a, b) => {
          const valueA = a.firstName ? a.firstName : "";
          const valueB = b.firstName ? b.firstName : "";
          return sortStatusEmployees.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatusEmployees.columnAccessor === "lastName") {
        sortedData.sort((a, b) => {
          const valueA = a.lastName ? a.lastName.toLowerCase() : "";
          const valueB = b.lastName ? b.lastName.toLowerCase() : "";
          return sortStatusEmployees.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatusEmployees.columnAccessor === "phoneNumber") {
        sortedData.sort((a, b) => {
          const valueA = a.phoneNumber ? a.phoneNumber.toLowerCase() : "";
          const valueB = b.phoneNumber ? b.phoneNumber.toLowerCase() : "";
          return sortStatusEmployees.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      }
      setSortedEmployeesData(sortedData);
      setEmployeesPageData(sortedData.slice(fromEmployees, toEmployees));
    };

    applySortingAndFiltering();
  }, [employeesInitialRecords, pageEmployees, sortStatusEmployees]);

  const columnsEmployees = [
    {
      accessor: "firstName",
      title: "Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen isimdeki ara."
          placeholder="İsim ile ara..."
          icon={<IconSearch size={16} />}
          value={firstNameQuery}
          onChange={(e) => setFirstNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: firstNameQuery !== "",
    },
    {
      accessor: "lastName",
      title: "Soyadı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Soyisim"
          description="Belirtilen soyisimdeki formları ara."
          placeholder="Soyisim ile ara..."
          icon={<IconSearch size={16} />}
          value={lastNameQuery}
          onChange={(e) => setLastNameQuery(e.currentTarget.value)}
        />
      ),
      iltering: lastNameQuery !== "",
    },
    {
      accessor: "phoneNumber",
      title: "Telefon Numarası",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Telefon Numarası"
          description="Telefon numarası ile ara."
          placeholder="Telefon numarası ara..."
          icon={<IconSearch size={16} />}
          value={phoneNumberQuery}
          onChange={(e) => setPhoneNumberQuery(e.currentTarget.value)}
        />
      ),
      filtering: phoneNumberQuery !== "",
    },
  ];

  const handleSelectedRecordsChange = (selectedRecords: any) => {
    console.log(selectedRecords);

    const invalidRecords = selectedRecords.filter(
      (record: any) => record.responsibleEngineers === ""
    );
    if (invalidRecords.length > 0) {
      Swal.fire({
        title: "Seçilen tarlaların sorumlu mühendis bilgisi bulunmamaktadır.",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#0097c4",
        confirmButtonText: "Tamam",
      });
    } else {
      setSelectedRecordsFields(selectedRecords);
    }
  };
  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[
          { title: "Görev Listesi", href: "/task/list" },
          { title: "Görev Atama", href: "#" },
        ]}
        title="Görev Atama"
      >
        <>
          <Box
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              style={{
                color: "#FFF",
                borderColor: "#000000",
                borderRadius: "2rem",
                fontWeight: 300,
                width: "100px",
                backgroundColor: "red",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconArrowLeft /> Geri
            </Button>
          </Box>
          <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
            <Suspense fallback={<CustomLoadingOverlay />}>
              <Paper p="xl" className="profile-paper" pos={"relative"}>
                <LoadingOverlay
                  visible={fetching}
                  overlayBlur={2}
                  loaderProps={{
                    size: "md",
                    color: "#0097c4",
                    variant: "oval",
                  }}
                  pos={"absolute"}
                />
                <Grid>
                  <Grid.Col lg={6} md={12} offsetLg={6}>
                    <Flex></Flex>
                  </Grid.Col>
                </Grid>
                <Space h="lg" />

                <Grid>
                  <Grid.Col sm={12} md={12}>
                    <Grid>
                      <Grid.Col sm={12} md={12}>
                        <Grid>
                          <Col span={3}>
                            <Text
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins-Medium",
                                color: "#136583",
                                marginTop: "10px",
                                paddingLeft: "8px",
                              }}
                            >
                              {" "}
                              Atama Tipi:
                            </Text>
                          </Col>
                          <Col span={6}>
                            <Radio.Group
                              value={assignType}
                              onChange={(e) => {
                                setAssignType(e);
                                setField(null);
                                setEmployeeIds(null);
                              }}
                            >
                              <Group mt="xs">
                                <Radio value="1" label="Tarla" />
                                <Radio value="2" label="Üretici" />
                              </Group>
                            </Radio.Group>
                          </Col>
                        </Grid>
                      </Grid.Col>
                      <Grid.Col sm={12} md={12}>
                        <Col span={12}>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontFamily: "Poppins-Medium",
                              color: "#136583",
                            }}
                          >
                            {" "}
                            Görev Adı:
                          </Text>
                          {detailFormTaskData && (
                            <Text>{detailFormTaskData.name}</Text>
                          )}
                        </Col>
                        <Col span={12}>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontFamily: "Poppins-Medium",
                              color: "#136583",
                            }}
                          >
                            {" "}
                            Görev Açıklaması:
                          </Text>
                          {detailFormTaskData && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: detailFormTaskData.description,
                              }}
                            />
                          )}
                        </Col>
                      </Grid.Col>
                    </Grid>
                    <Grid>
                      <Grid.Col sm={12} md={6}>
                        <Flex>
                          <Button
                            style={{
                              backgroundColor: "#0097c4",
                              paddingBottom: "11.5px",
                              paddingTop: "11.5px",
                              width: "100%",
                              borderRadius: 32,
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "12px",
                              alignItems: "right",
                              fontFamily: "Poppins-Medium",
                            }}
                            onClick={saveButtonClick}
                          >
                            Ata
                          </Button>
                          &nbsp; &nbsp; &nbsp;
                          <Button
                            style={{
                              paddingBottom: "11.5px",
                              paddingTop: "11.5px",
                              width: "100%",
                              borderRadius: 32,
                              backgroundColor: "#FFFFFF",
                              color: "#000000",
                              border: "0.5px solid #000000",
                              fontFamily: "Poppins-Light",
                              fontSize: "12px",
                            }}
                            onClick={resetButtonClick}
                          >
                            Temizle
                          </Button>
                        </Flex>
                      </Grid.Col>
                    </Grid>{" "}
                  </Grid.Col>
                  <Grid.Col sm={12} md={12}>
                    <Grid>
                      {assignType == "1" && (
                        <Grid.Col span={12}>
                          <h4>Tarlalar</h4>
                          <DataTable
                            withColumnBorders
                            highlightOnHover
                            minHeight={250}
                            noRecordsText="Tarla Bulunamadı..."
                            className="datatable"
                            records={fieldsPageData}
                            columns={columnsFields}
                            totalRecords={sortedFieldsData.length}
                            recordsPerPage={PAGE_SIZE}
                            page={pageFields}
                            onPageChange={(p) => setPageFields(p)}
                            fetching={fetching}
                            loaderColor="#0097c4"
                            sortStatus={sortStatusFields}
                            onSortStatusChange={handleSortStatusChangeFields}
                            sortIcons={{
                              sorted: <IconSortDescending size={14} />,
                              unsorted: <IconSortDescending size={14} />,
                            }}
                            selectedRecords={selectedRecordsFields}
                            onSelectedRecordsChange={
                              handleSelectedRecordsChange
                            }
                          />
                        </Grid.Col>
                      )}

                      {assignType == "2" && (
                        <Grid.Col span={12}>
                          <h4>Üreticiler</h4>
                          <DataTable
                            withColumnBorders
                            highlightOnHover
                            minHeight={250}
                            noRecordsText="Üretici Bulunamadı..."
                            className="datatable"
                            records={employeesPageData}
                            columns={columnsEmployees}
                            totalRecords={sortedEmployeesData.length}
                            recordsPerPage={PAGE_SIZE}
                            page={pageEmployees}
                            onPageChange={(p) => setPageEmployees(p)}
                            fetching={fetching}
                            loaderColor="#0097c4"
                            sortStatus={sortStatusEmployees}
                            onSortStatusChange={handleSortStatusChangeEmployees}
                            sortIcons={{
                              sorted: <IconSortDescending size={14} />,
                              unsorted: <IconSortDescending size={14} />,
                            }}
                            selectedRecords={selectedRecordsEmployees}
                            onSelectedRecordsChange={
                              setSelectedRecordsEmployees
                            }
                          />
                        </Grid.Col>
                      )}
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Paper>
            </Suspense>
          </Paper>
        </>
      </MainWrapper>
    </Suspense>
  );
};

export default AssignSurvey;

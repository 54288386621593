import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Loader,
  Modal,
  Paper,
  Select,
  Text,
} from "@mantine/core";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { useAppDispatch } from "../../../satellite/connector/ModuleHook";
import { MyFieldResponse } from "../../data/response/MyFieldResponses";
import { useWindowSize } from "@uidotdev/usehooks";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import AxiosHelper from "../../../common/util/AxiosHelper";
import { Field } from "../../../notifications/presentation/types/field";
import { CityService } from "../../interactor/CityInteractor";
import { DistrictService } from "../../interactor/DistrictInteractor";
import { DistrictRequest } from "../../data/request/DistrictRequest";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../connector/ModuleStore";

const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);

mapboxgl.accessToken =
  "pk.eyJ1IjoiemFoaWRpbWVjZSIsImEiOiJjbHZldnl3MG8wYmNvMnFvNHJtMm44aXZhIn0.v524HPF3Z_kBWdR76fAzUQ";

interface AccountProps {
  seasonId?: string;
}

const SatelliteAllFields: React.FC<AccountProps> = ({}) => {
  const [fetching, setFetching] = useState(false);
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const [notes, setNotes] = useState<string | null>(null); // State to manage notes
  const [noteTitle, setNoteTitle] = useState<string>("");
  const [isClosed, setIsClosed] = useState(false);

  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get("fieldId");

  const [fieldState, setFieldState] = useState<any>([]);
  const [exitsCities, setExitsCities] = useState<any>([]);
  const [exitsDistricts, setExitsDistricts] = useState<any>([]);
  const [filteredFieldState, setFilteredFieldState] = useState<any>([]);
  const [mapWidth, setMapWidth] = useState(0);
  const paperRef = useRef<any>(null);

  const [city, setCity] = useState<string | null>(null);
  const [district, setDistrict] = useState<string | null>(null);

  useEffect(() => {
    getData();
  }, []);

  const RezizeMap = () => {
    if (paperRef?.current) {
      setMapWidth(paperRef.current.offsetWidth - 20);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      RezizeMap();
      window.addEventListener("resize", RezizeMap);
      return () => window.removeEventListener("resize", RezizeMap);
    }, 2000);
  }, [size.width, paperRef, fetching]);

  const getData = async () => {
    const response = await AxiosHelper.getInstance().get<MyFieldResponse>(
      "corporate/fields"
    );
    if (response) {
      setFieldState(response.data.data.fields);
    }
  };

  //**************MAPBOX*********************/

  const nodeAll = useRef(null);

  const [isLoding, setIsLoading] = useState(false);

  const [dayErrorTitle, setDayErrorTitle] = useState<string | null>(null);
  const [dayErrorMessage, setDayErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (nodeAll?.current && filteredFieldState && mapWidth > 0) {
      dispatch(CityService());
      var getFieldInfoState: Field = filteredFieldState.find(
        (item: any) => item.id == selectedFieldId
      );
      const map = new mapboxgl.Map({
        container: nodeAll.current ?? "",
        style: "mapbox://styles/zahidimece/clvew32w3012h01qp2uctah1f",
        center: [35.2433, 38.9637] as LngLatLike,
        zoom: 5,
        maxZoom: 18,
        minZoom: 5,
      });

      if (fieldId) {
        const targetField = filteredFieldState.find(
          (field: Field) => field.id === fieldId
        );
        if (targetField) {
          map.flyTo({
            center: [
              targetField.address.longitude,
              targetField.address.latitude,
            ],
            zoom: 15,
            essential: true,
          });
        }
      }
      map.on("load", () => {
        filteredFieldState.map((getFieldInfoState: Field, index: number) => {
          if (getFieldInfoState.address.coordinates) {
            map.addSource("polygon_" + index.toString(), {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [
                    polygonBounds(getFieldInfoState.address.coordinates),
                  ],
                },
                properties: {},
              } as GeoJSON.Feature<GeoJSON.Polygon>,
            });
            map.addLayer({
              id: "polygon-fill_" + index.toString(),
              type: "fill",
              source: "polygon_" + index.toString(),
              layout: {},
              paint: {
                "fill-color": "rgba(0, 0, 0, 0)",
                "fill-opacity": 0,
              },
            });
            map.addLayer({
              id: "polygon-outline_" + index.toString(),
              type: "line",
              source: "polygon_" + index.toString(),
              layout: {},
              paint: {
                "line-color": "#dc1919",
                "line-width": 3,
              },
            });
          }
        });

        if (filteredFieldState) {
          const geojson = {
            type: "FeatureCollection",
            features: filteredFieldState.map((getFieldInfoState: Field) => ({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  getFieldInfoState.address.longitude,
                  getFieldInfoState.address.latitude,
                ],
              },
              properties: {
                fieldName: getFieldInfoState.fieldName,
                plantName: getFieldInfoState.seasons
                  ? getFieldInfoState.seasons[
                      getFieldInfoState.seasons.length - 1
                    ].plantName
                  : "",
                farmerName: getFieldInfoState.farmerName,
                id: getFieldInfoState.id,
              },
            })),
          } as any;

          map.addSource("fields", {
            type: "geojson",
            data: geojson,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
          });

          map.addLayer({
            id: "clusters",
            type: "circle",
            source: "fields",
            filter: ["has", "point_count"],
            paint: {
              "circle-color": [
                "step",
                ["get", "point_count"],
                "#51bbd6",
                100,
                "#f1f075",
                750,
                "#f28cb1",
              ],
              "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                100,
                30,
                750,
                40,
              ],
            },
          });

          map.addLayer({
            id: "cluster-count",
            type: "symbol",
            source: "fields",
            filter: ["has", "point_count"],
            layout: {
              "text-field": "{point_count_abbreviated}",
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 12,
            },
          });

          map.addLayer({
            id: "unclustered-point",
            type: "circle",
            source: "fields",
            filter: ["!", ["has", "point_count"]],
            paint: {
              "circle-color": "#04a7d8",
              "circle-radius": 6,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#fff",
            },
          });

          map.on("click", "unclustered-point", (e: any) => {
            const coordinates: any = e.features[0].geometry.coordinates.slice();
            console.log(coordinates);
            const { fieldName, plantName, farmerName, id } =
              e.features[0].properties;
            const popupHTML = `
        <div style="background-color:white; color:black; width:210px;">
          <ul style="padding:0px;margin:0px;list-style-type:none;">
            <li><b>Tarla Adı:&nbsp;</b>${fieldName}</li>
            <li><b>Ürün:&nbsp;</b>${plantName ?? "-"}</li>
            <li><b>İlgili:&nbsp;</b>${farmerName ?? "-"}</li>
            <li><a style="cursor:pointer;" href="/myFields/detail?id=${id}" target="_blank">Detaya Git</a></li>
          <li><a style="cursor:pointer;" href="https://www.google.com/maps?q=${
            coordinates[1]
          },${coordinates[0]}" target="_blank">Konuma Git</a></li>
            </ul>
        </div>`;

            new mapboxgl.Popup({ offset: 25 })
              .setLngLat(coordinates)
              .setHTML(popupHTML)
              .addTo(map);
          });

          map.on("click", "clusters", (e) => {
            const features: any = map.queryRenderedFeatures(e.point, {
              layers: ["clusters"],
            });
            const clusterId = features[0].properties.cluster_id;
            const source = map.getSource("fields") as mapboxgl.GeoJSONSource;

            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
          });

          map.on("mouseenter", "clusters", () => {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", "clusters", () => {
            map.getCanvas().style.cursor = "";
          });

          const bounds = new mapboxgl.LngLatBounds();
          geojson.features.forEach((feature: any) => {
            bounds.extend(feature.geometry.coordinates as [number, number]);
          });
        }

        return () => {
          map.remove();
        };
      });
    }
  }, [filteredFieldState, nodeAll, mapWidth, fieldId]);

  useEffect(() => {
    if (fieldState) {
      setFilteredFieldState(fieldState);
      setExitsCities(fieldState.map((item: any) => item.address?.city));
      setExitsDistricts(fieldState.map((item: any) => item.address?.district));
    }
  }, [fieldState]);

  useEffect(() => {
    if (district) {
      setFilteredFieldState(
        fieldState.filter(
          (item: any) => item.address?.district?.id === district
        )
      );
    } else if (city) {
      setFilteredFieldState(
        fieldState.filter((item: any) => item.address?.city?.id === city)
      );
    } else {
      setFilteredFieldState(fieldState);
    }
  }, [city, district]);

  const [isOpen, setIsOpen] = useState(false);

  const cityService = useSelector((state: RootState) => state.cityState.value);

  const cityData = cityService?.data?.cities;

  const districtService = useSelector(
    (state: RootState) => state.districtState.value
  );
  const districtData = districtService?.data?.districts;

  const handleCity = () => {
    setDistrict(null);

    dispatch(CityService());
  };

  const handleDistrict = () => {
    if (city != null) {
      setDistrict(null);
      dispatch(DistrictService(new DistrictRequest(city, "")));
    }
  };

  const toggleTooltip = () => {
    setIsOpen(!isOpen);
  };

  const polygonBounds = (coordinates: any[]) => {
    if (!coordinates || coordinates.length === 0) {
      return [0, 0];
    }
    return coordinates.map((coord) => [coord.longitude, coord.latitude]);
  };

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[
          { title: "Tarlalarım", href: "/myFields" },
          { title: "Harita Görünümü", href: "#" },
        ]}
        title="Harita Görünümü"
      >
        <Suspense fallback={<CustomLoadingOverlay />}>
          <Container fluid ref={paperRef}>
            <Grid>
              <Grid.Col span={12}>
                <Flex justify={"flex-start"} align={"flex-start"}>
                  {fetching ? (
                    <Loader color="#0097c4"></Loader>
                  ) : (
                    fieldState && (
                      <>
                        <div style={{ position: "relative", width: "100%" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: 10,
                              left: 10,
                              zIndex: 1,
                              display: "flex",
                            }}
                          >
                            {cityData && exitsCities && (
                              <div style={{ display: "flex" }}>
                                <Select
                                  placeholder="İl Seçin"
                                  searchable
                                  nothingFound="Veri Bulunamadı"
                                  data={
                                    cityData
                                      ?.filter((city) =>
                                        exitsCities.some(
                                          (item: any) => item.id === city.id
                                        )
                                      )
                                      .map((city: any) => ({
                                        value: city.id,
                                        label: city.name,
                                      })) || []
                                  }
                                  onChange={(value) => {
                                    setCity(value);
                                  }}
                                  onSelect={() => {
                                    handleDistrict();
                                  }}
                                  sx={{
                                    Input: {
                                      height: "48px",
                                    },
                                  }}
                                  clearable={true}
                                />
                              </div>
                            )}
                            &nbsp;&nbsp;&nbsp;
                            {districtData && exitsDistricts && (
                              <Select
                                placeholder="İlçe Seçin"
                                searchable
                                nothingFound="Veri Bulunamadı"
                                data={
                                  districtData
                                    ?.filter((district) =>
                                      exitsDistricts.some(
                                        (item: any) => item.id === district.id
                                      )
                                    )
                                    .map((district: any) => ({
                                      value: district.id,
                                      label: district.name,
                                    })) || []
                                }
                                value={district}
                                onChange={(value) => setDistrict(value)}
                                onSelect={() => {}}
                                sx={{
                                  Input: {
                                    height: "48px",
                                  },
                                }}
                                clearable={true}
                              />
                            )}
                          </div>
                          <Box>
                            <div
                              ref={nodeAll}
                              style={{
                                height: "100vh",
                                width: mapWidth.toString() + "px",
                              }}
                            ></div>
                          </Box>
                        </div>
                        <Modal
                          opened={dayErrorTitle !== null}
                          onClose={() => setDayErrorTitle(null)}
                          className="custom-modal"
                          title={dayErrorTitle}
                          centered
                          size={"lg"}
                          withCloseButton
                          styles={{
                            content: {
                              borderRadius: "2rem",
                              padding: ".8rem",
                            },
                          }}
                          sx={{
                            ".mantine-Modal-title": {
                              fontSize: "20px !important",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          {dayErrorMessage && <Text>{dayErrorMessage}</Text>}
                        </Modal>
                      </>
                    )
                  )}
                </Flex>
              </Grid.Col>
            </Grid>
          </Container>
        </Suspense>
      </MainWrapper>
    </Suspense>
  );
};

export default SatelliteAllFields;

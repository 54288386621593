import {
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  rem,
  Space,
  Stack,
  TextInput,
  Tooltip,
} from "@mantine/core";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../connector/ModuleHook";
import { RootState } from "./../connector/ModuleStore";
import CustomLoadingOverlay from "../../utils/CustomLoadingOverlay";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { IconSearch, IconSortDescending } from "@tabler/icons-react";
import {
  CompleteStateFormTaskService,
  GetFormTaskListService,
} from "../interractor/SurveyInterractor";
import { useSelector } from "react-redux";
import { useDebouncedValue } from "@mantine/hooks";
import moment from "moment";
import { DatePicker, DatesProvider } from "@mantine/dates";
import { useNavigate } from "react-router-dom";
import {
  IconPlus,
  IconList,
  IconEdit,
  IconUserEdit,
  IconCheck,
} from "@tabler/icons-react";
import { FormTaskDto } from "../data/dto/FormTaskDto";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { CompleteStateFormTaskRequest } from "../data/request/CompleteStateFormTaskRequest";

const PAGE_SIZE = 10;
const MainWrapper = lazy(
  () => import("../../../app/common/presentation/view/component/mainWrapper")
);

interface TasksProps {}

const Tasks: React.FC<TasksProps> = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<any[]>([]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "createdDate",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [nameQuery, setNameQuery] = useState("");
  const [debouncedNameQuery] = useDebouncedValue(nameQuery, 200);

  const [descriptionQuery, setDescriptionQuery] = useState("");
  const [debouncedDescriptionQuery] = useDebouncedValue(descriptionQuery, 200);

  const [startDateQuery, setStartDateQuery] = useState("");
  const [debouncedStartDateQuery] = useDebouncedValue(startDateQuery, 200);

  const [endDateQuery, setEndDateQuery] = useState("");
  const [debouncedEndDateQuery] = useDebouncedValue(endDateQuery, 200);

  const [formNameQuery, setFormNameQuery] = useState("");
  const [debouncedFormNameQuery] = useDebouncedValue(formNameQuery, 200);

  const [assignedCountQuery, setAssignedCountQuery] = useState("");
  const [debouncedAssignedCountQuery] = useDebouncedValue(
    assignedCountQuery,
    200
  );

  const [completedCountQuery, setCompletedCountQuery] = useState("");
  const [debouncedCompletedCountQuery] = useDebouncedValue(
    completedCountQuery,
    200
  );

  const [progressCountQuery, setProgressCountQuery] = useState("");
  const [debouncedProgressCountQuery] = useDebouncedValue(
    progressCountQuery,
    200
  );

  const [formTaskStateQuery, setFormTaskStateQuery] = useState("");
  const [debouncedFormTaskStateQuery] = useDebouncedValue(
    formTaskStateQuery,
    200
  );

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  useEffect(() => {
    setIsFetching(true);
    dispatch(GetFormTaskListService());
  }, []);

  const getFormTaskListService = useSelector(
    (state: RootState) => state.GetFormTaskListState.value
  );

  const formTaskData = getFormTaskListService?.data?.formTaskDtos || [];

  const completeTask = (id: string) => {
    Swal.fire({
      title: "Görev'i tamamlamak istediğinize emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Tamamla!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        completeTaskHandler(id);
      }
    });
  };

  const completeTaskHandler = async (id: string) => {
    let response = await dispatch(
      CompleteStateFormTaskService({
        formTaskId: id,
      } as CompleteStateFormTaskRequest)
    ).unwrap();
    if (response) {
      if (response?.success) {
        Swal.fire({
          title: "Görev başarıyla tamamlandı durumuna getirildi.",
          icon: "success",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        });
      } else {
        Swal.fire({
          title: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
          icon: "warning",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        });
      }
    }
  };

  useEffect(() => {
    if (formTaskData) {
      let formTasks = formTaskData.map((item: FormTaskDto) => {
        return {
          id: item.id,
          name: item.name,
          description:
            item.description.length > 50
              ? item.description.substring(0, 50) + "..."
              : item.description,
          startDate: moment(item.startDate).format("DD.MM.YYYY"),
          endDate: moment(item.endDate).format("DD.MM.YYYY"),
          formName: item.formName,
          assignedCount: item.assignedCount,
          completedCount: item.completedCount,
          progressCount: (item.assignedCount ?? 0) - (item.completedCount ?? 0),
          formTaskState: item.formTaskState == 0 ? "Aktif" : "Tamamlanmış",
        };
      });
      console.log(formTasks);
      setPageData(formTasks);
      setInitialRecords(formTasks);
      setIsFetching(false);
    }
  }, [formTaskData]);

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      console.log(endDateQuery);
      const filteredData = initialRecords.filter(
        ({
          id,
          name,
          description,
          startDate,
          endDate,
          formName,
          assignedCount,
          completedCount,
          progressCount,
          formTaskState,
        }) => {
          if (
            debouncedNameQuery !== "" &&
            nameQuery &&
            !`${name}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedDescriptionQuery !== "" &&
            descriptionQuery &&
            !`${description}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedDescriptionQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedFormNameQuery !== "" &&
            formNameQuery &&
            !`${formName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFormNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedStartDateQuery !== "" &&
            startDateQuery &&
            startDate !== debouncedStartDateQuery
          ) {
            return false;
          }
          if (
            debouncedEndDateQuery !== "" &&
            endDateQuery &&
            endDate !== debouncedEndDateQuery
          ) {
            return false;
          }
          if (
            debouncedAssignedCountQuery !== "" &&
            assignedCountQuery &&
            !`${assignedCount}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedAssignedCountQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedCompletedCountQuery !== "" &&
            completedCountQuery &&
            !`${completedCount}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedCompletedCountQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedProgressCountQuery !== "" &&
            progressCountQuery &&
            !`${progressCount}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedProgressCountQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedFormTaskStateQuery !== "" &&
            formTaskStateQuery &&
            !`${formTaskState}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFormTaskStateQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      );
      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "name") {
        sortedData.sort((a, b) => {
          const valueA = a.name ? a.name : "";
          const valueB = b.name ? b.name : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "description") {
        sortedData.sort((a, b) => {
          const valueA = a.description ? a.description.toLowerCase() : "";
          const valueB = b.description ? b.description.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "formName") {
        sortedData.sort((a, b) => {
          const valueA = a.formName ? a.formName.toLowerCase() : "";
          const valueB = b.formName ? b.formName.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "startDate") {
        sortedData.sort((a, b) => {
          const dateA = a.startDate
            ? moment(a.startDate, "DD.MM.YYYY")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan
          const dateB = b.startDate
            ? moment(b.startDate, "DD.MM.YYYY")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan

          return sortStatus.direction === "asc"
            ? dateA.diff(dateB)
            : dateB.diff(dateA);
        });
      } else if (sortStatus.columnAccessor === "endDate") {
        sortedData.sort((a, b) => {
          const dateA = a.endDate ? moment(a.endDate, "DD.MM.YYYY") : moment(0); // Eğer tarih yoksa, epoch zamanını kullan
          const dateB = b.endDate ? moment(b.endDate, "DD.MM.YYYY") : moment(0); // Eğer tarih yoksa, epoch zamanını kullan

          return sortStatus.direction === "asc"
            ? dateA.diff(dateB)
            : dateB.diff(dateA);
        });
      } else if (sortStatus.columnAccessor === "assignedCount") {
        sortedData.sort((a, b) => {
          return sortStatus.direction === "asc"
            ? a.assignedCount - b.assignedCount
            : b.assignedCount - a.assignedCount;
        });
      } else if (sortStatus.columnAccessor === "completedCount") {
        sortedData.sort((a, b) => {
          return sortStatus.direction === "asc"
            ? a.completedCount - b.completedCount
            : b.completedCount - a.completedCount;
        });
      } else if (sortStatus.columnAccessor === "progressCount") {
        sortedData.sort((a, b) => {
          return sortStatus.direction === "asc"
            ? a.progressCount - b.progressCount
            : b.progressCount - a.progressCount;
        });
      } else if (sortStatus.columnAccessor === "formTaskState") {
        sortedData.sort((a, b) => {
          const valueA = a.formTaskState ? a.formTaskState.toLowerCase() : "";
          const valueB = b.formTaskState ? b.formTaskState.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus, endDateQuery, startDateQuery]);

  const columns = [
    {
      accessor: "name",
      title: "Görev Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Görev adlı"
          description="Belirtilen isimdeki görevleri ara."
          placeholder="Görev adı ara..."
          icon={<IconSearch size={16} />}
          value={nameQuery}
          onChange={(e) => setNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: nameQuery !== "",
    },
    // {
    //   accessor: "description",
    //   title: "Açıklama",
    //   textAlignment: "center" as DataTableColumnTextAlignment,
    //   sortable: true,
    //   ellipsis: true,
    //   filter: (
    //     <TextInput
    //       label="Açıklama"
    //       description="Belirtilen açıklamayı içeren görevleri ara."
    //       placeholder="Açıkalama ile ara..."
    //       icon={<IconSearch size={16} />}
    //       value={descriptionQuery}
    //       onChange={(e) => setDescriptionQuery(e.currentTarget.value)}
    //     />
    //   ),
    //   filtering: descriptionQuery !== "",
    // },
    {
      accessor: "startDate",
      title: "Başlangıç Tarihi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: ({ close }: { close: any }) => (
        <DatesProvider
          settings={{
            locale: dayjs.locale("tr"),
            firstDayOfWeek: 1,
            weekendDays: [0, 6],
          }}
        >
          <DatePicker
            value={
              startDateQuery == ""
                ? null
                : moment(startDateQuery, "DD.MM.YYYY").toDate()
            }
            onChange={(date) =>
              setStartDateQuery(moment(date).format("DD.MM.YYYY"))
            }
          />
          <Button
            disabled={startDateQuery == ""}
            color="red"
            onClick={() => {
              setStartDateQuery("");
              close();
            }}
          >
            Reset
          </Button>
        </DatesProvider>
      ),
      filtering: startDateQuery !== "",
    },
    {
      accessor: "endDate",
      title: "Bitiş Tarihi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: ({ close }: { close: any }) => (
        <DatesProvider
          settings={{
            locale: dayjs.locale("tr"),
            firstDayOfWeek: 1,
            weekendDays: [0, 6],
          }}
        >
          <DatePicker
            lang="tr"
            value={
              endDateQuery === ""
                ? null
                : moment(endDateQuery, "DD.MM.YYYY").toDate()
            }
            onChange={(date) =>
              setEndDateQuery(moment(date).format("DD.MM.YYYY"))
            }
          />
          <Button
            disabled={endDateQuery === ""}
            color="red"
            onClick={() => {
              setEndDateQuery("");
              close();
            }}
          >
            Reset
          </Button>
        </DatesProvider>
      ),
      filtering: endDateQuery !== "",
    },
    {
      accessor: "formName",
      title: "Form Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Form Adı"
          description="Belirtilen Form adını içeren görevleri ara."
          placeholder="Form adı ile ara..."
          icon={<IconSearch size={16} />}
          value={formNameQuery}
          onChange={(e) => setFormNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: formNameQuery !== "",
    },
    {
      accessor: "assignedCount",
      title: "Atanmış Kişi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Atanmış Görev Sayısı"
          description="Atanmış görev sayısı içeren görevleri ara."
          placeholder="Atanmış görev sayyısı ile ara..."
          icon={<IconSearch size={16} />}
          value={assignedCountQuery}
          onChange={(e) => setAssignedCountQuery(e.currentTarget.value)}
        />
      ),
      filtering: assignedCountQuery !== "",
    },
    {
      accessor: "completedCount",
      title: "Tamamlanmış Görev",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Tamamlanmış Görev"
          description="Tamamlanmış görev sayısı içeren görevleri ara."
          placeholder="Tamamlanmış görev sayyısı ile ara..."
          icon={<IconSearch size={16} />}
          value={completedCountQuery}
          onChange={(e) => setCompletedCountQuery(e.currentTarget.value)}
        />
      ),
      filtering: completedCountQuery !== "",
    },
    {
      accessor: "progressCount",
      title: "Devam Eden Görev Sayısı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Devam Eden Görev Sayısı"
          description="Devam Eden görev sayısı içeren görevleri ara."
          placeholder="Devam Eden görev sayyısı ile ara..."
          icon={<IconSearch size={16} />}
          value={progressCountQuery}
          onChange={(e) => setProgressCountQuery(e.currentTarget.value)}
        />
      ),
      filtering: progressCountQuery !== "",
    },
    {
      accessor: "formTaskState",
      title: "Görev Durumu",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Görev Durumu"
          description="Görev Durumu içeren görevleri ara."
          placeholder="Görev Durumu ile ara..."
          icon={<IconSearch size={16} />}
          value={formTaskStateQuery}
          onChange={(e) => setFormTaskStateQuery(e.currentTarget.value)}
        />
      ),
      filtering: formTaskStateQuery !== "",
    },
    {
      accessor: "isRead",
      title: "Aksiyon",
      textAlignment: "center" as DataTableColumnTextAlignment,

      render: (record: any, index: number) => (
        <Group spacing={4} position="center" noWrap>
          {true && (
            <Tooltip label="Detay">
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#FFF",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "80px",
                  fontWeight: 300,
                  backgroundColor: "#7169d2",
                }}
                onClick={() => {
                  navigate(`/task/detail/${record.id}`);
                }}
              >
                <IconList />
              </Button>
            </Tooltip>
          )}
          {true && (
            <Tooltip label="Düzenle">
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#FFF",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "80px",
                  fontWeight: 300,
                  backgroundColor: "#7169d2",
                }}
                onClick={() => {
                  navigate(`/task/update/${record.id}`);
                }}
              >
                <IconEdit />
              </Button>
            </Tooltip>
          )}
          {true && (
            <Tooltip label="Görev Ata">
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#FFF",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "80px",
                  fontWeight: 300,
                  backgroundColor: "#7169d2",
                }}
                onClick={() => {
                  navigate(`/task/assign/${record.id}`);
                }}
              >
                <IconUserEdit />
              </Button>
            </Tooltip>
          )}
          {true && (
            <Tooltip label="Tamamla">
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#FFF",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "80px",
                  fontWeight: 300,
                  backgroundColor: "#7169d2",
                }}
                onClick={() => {
                  completeTask(record.id);
                }}
              >
                <IconCheck />
              </Button>
            </Tooltip>
          )}
        </Group>
      ),
    },
  ];

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Görev Listesi", href: "#" }]}
        title="Görev Listesi"
      >
        <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
          <Suspense fallback={<CustomLoadingOverlay />}>
            <Paper p="xl" className="profile-paper" pos={"relative"}>
              <LoadingOverlay
                visible={isFetching}
                overlayBlur={2}
                loaderProps={{
                  size: "md",
                  color: "#0097c4",
                  variant: "oval",
                }}
                pos={"absolute"}
              />
              <Button
                style={{
                  borderRadius: 32,
                  color: "#FFF",
                  fontFamily: "Poppins-Medium",
                  fontSize: rem(12),
                  backgroundColor: "#0097c4",
                  textAlign: "center",
                }}
                onClick={() => navigate("/task/add")}
              >
                <IconPlus /> Yeni Görev Ekle
              </Button>

              <Space h="lg" />
              <Grid>
                <Grid.Col sm={12} md={12} style={{ marginTop: "20px" }}>
                  <DataTable
                    withColumnBorders
                    highlightOnHover
                    minHeight={250}
                    noRecordsText="Görev Bulunamadı..."
                    className="datatable"
                    records={pageData}
                    columns={columns}
                    totalRecords={sortedData.length}
                    recordsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    fetching={fetching}
                    loaderColor="#0097c4"
                    sortStatus={sortStatus}
                    onSortStatusChange={handleSortStatusChange}
                    sortIcons={{
                      sorted: <IconSortDescending size={14} />,
                      unsorted: <IconSortDescending size={14} />,
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Paper>
          </Suspense>
        </Paper>
      </MainWrapper>
    </Suspense>
  );
};

export default Tasks;
